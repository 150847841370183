.select-multiple {
    cursor: pointer;

    &__choice {
        padding-left: 0;
        color: $primary;
        margin: 5px 0;
        background-color: white;
        justify-content: space-between;
        display: flex;

        &.hidden {
            display: none;
        }

        input {
            margin-right: 7px;
            margin-left: 10px;
        }
    }

    &__choices {
        display: none;
        list-style: none;
        margin-bottom: 0;
        max-height: 336px;
        background-color: rgba(255, 255, 255, 1);
        overflow-y: auto;
        border-top: solid 3px #ff5a5f;
        -webkit-box-shadow: 0 5px 6px rgb(0 0 0 / 25%);
        box-shadow: 0 5px 6px rgb(0 0 0 / 25%);
        padding: 8px 8px 8px 8px;
    }

    // Default is hidden
    &__display &__choices {
        display: block;
        position: absolute;
        white-space: nowrap;
        z-index: 3;
    }

    &__select {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    &__select-input {
        background: none;
        border: none;
        width: 100%;
        margin-right: 16px;
        color: $primary;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
    }

    input {
        outline: none;
    }
}
