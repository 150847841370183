.layout {
    @apply w-full;

    &__content {
        @apply w-full px-l pb-[100px] pt-l;

        &--half {
            @apply mx-auto w-[700px] py-5xl;
        }
    }

    &--content {
        @apply bg-white pb-[100px];
    }

    &--height-full {
        @apply min-h-[100vh];
    }
}
