.circuit-pricing {
    &__price {
        max-width: 150px;
    }

    .common__input-container {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }
}

.remove__button {
    font-size: 14px;
    font-weight: 600;
    color: $red-expired;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.remove__button {
    font-size: 14px;
    font-weight: 600;
    color: $red-expired;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.circuit-pricing-collection {
    margin-top: 1rem;
    padding: 0.5rem;

    .common__input-container {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }
}
