.booking {
    &-quote-sent {
        @apply border border-solid border-border-brand fill-content-brand;
    }

    &-waiting {
        @apply border border-solid border-status-warning fill-status-warning;
    }

    &-validated {
        @apply border border-solid border-status-warning fill-status-warning;
    }

    &-confirmed {
        @apply border border-solid border-status-positive fill-status-positive;
    }

    &-cancelled {
        @apply border border-solid border-status-negative fill-status-negative;
    }
}
