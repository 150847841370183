$primary: #00334d;
$dkt-yellow: #ffea28;

$content-negative: #e32630;
$content-positive: #23a942;

$tertiary: #0082c3;
$brand-dark: #00689d;
$brand-light: #e7f3f9;

$warning: #eab308;
$error: #ef4444;
$success: #22c55e;
$info: #e7f3f9;

$red-expired: #ff5a5f;
$blue-secondary: #007dbc;

$gray-secondary: #cddce6;
