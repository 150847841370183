.focus-layout {
    height: calc(100vh - 170px);
    width: 100%;
    background: #ffffff;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;

    // Resets margin
    .common__input-container {
        margin: 0;
    }

    &__form {
        width: 730px;
    }
}
