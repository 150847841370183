.users {
    &__button {
        margin: 30px 5px;
    }

    &__listing {
        margin: 0 auto;
        height: fit-content;
        width: 100%;
    }

    &__categories {
        color: white;
        border-radius: 10px 10px 0 0;

        &--background {
            background-color: $primary;
        }
    }

    &__categories:hover {
        background-color: $primary !important;
    }

    &__block {
        margin-top: 20px;
        padding: 30px;
        background-color: aliceblue;
        border-radius: 30px;
    }

    &__row {
        max-width: 300px !important;
    }
}

.users__row__cell {
    &--align-left {
        align-items: start;
    }
    &--align-center {
        align-items: center;
    }
}
