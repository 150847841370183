.circuit-slideshow {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 32px;
    }

    &__content-container {
        display: flex;
    }

    &__path {
        margin: auto 0;
    }

    &__picture-container {
        width: auto !important;

        &--size {
            max-height: 200px;
            width: auto;
        }
    }
}

.add-picture {
    &__container {
        min-height: 260px;
        border: 1px dashed $gray-secondary;
        padding: 48px 16px;
        margin: 32px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        color: $primary;
        font-size: 14px;
        max-width: 500px;
        text-align: center;

        &--accent {
            font-weight: 600;

            &--light {
                color: $tertiary;
            }

            &--big {
                font-size: 24px;
            }
        }
    }

    &__loader {
        width: 48px;
        height: 48px;
        border: 5px solid $primary;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;

        &--active {
            opacity: 0.4;
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
