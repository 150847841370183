@import '../_variables.scss';

.program {
    &__actions {
        width: 100% !important;
        max-width: 200px;
        display: flex;
        flex-direction: row;
        margin: auto;

        button {
            margin: auto;
        }
    }

    &__actions-btn {
        padding: 0;
        margin: 5px auto;
        width: 100%;
    }

    .row-action-btn {
        margin-left: 10px;
    }

    &__small-column {
        min-width: 100px;
    }

    &__medium-column {
        min-width: 150px;
    }

    &__large-column {
        width: 100%;
    }

    &__input-small {
        max-width: 150px;
    }

    &__input-medium {
        max-width: 300px;
    }

    &__input-container {
        display: flex;
        column-gap: 30px;
    }

    &__step-row {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        flex: 2 1 auto;

        * {
            max-width: 250px;
        }
    }
}

.interval__widget {
    table {
        width: 100%;
        tr {
            box-shadow: none !important;
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 12px;
        }

        thead {
            color: $primary;
            display: none;
        }

        td {
            border: none !important;
            padding: 0;
            width: 50%;
        }
    }
}
