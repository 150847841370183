.circuits {
    &__button {
        margin: 30px 5px;
    }

    &__listing {
        margin: 0 auto;
        height: fit-content;
        width: 100%;
    }

    &__categories {
        color: white;
        border-radius: 10px 10px 0 0;

        &--background {
            background-color: $primary;
        }
    }

    &__categories:hover {
        background-color: $primary !important;
    }

    &__block {
        margin: 20px 0 50px 0;
        padding: 30px;
        background-color: aliceblue;
        border-radius: 30px;
    }

    &__row {
        max-width: calc(100% - 800px);
        width: 100%;

        &--size {
            max-width: 200px !important;
            width: 100% !important;
        }
    }
}
