.input-number {
    @apply inline-flex leading-6;

    &__value {
        @apply inline-flex h-4xl w-4xl text-center text-content-neutral;

        // We need the important to override global default style
        @apply min-h-[auto] rounded-none border border-solid border-border-quiet #{!important};

        &.warning {
            @apply border-status-negative #{!important};
        }

        &:focus {
            @apply border-border-brand ring-transparent #{!important};
        }
    }

    &__less {
        @apply border-r-0 text-content-brand #{!important};

        &:disabled {
            @apply opacity-[0.38];
        }
    }

    &__more {
        @apply border-l-0 text-content-brand #{!important};

        &:disabled {
            @apply opacity-[0.38];
        }
    }

    &__label {
        @apply text-content-neutral;
    }

    button {
        @apply relative m-0 h-4xl w-4xl items-center justify-center border border-solid
        border-border-quiet bg-white text-content-brand outline-0 enabled:cursor-pointer enabled:hover:bg-secondary-regular-container-hover enabled:active:bg-active-primary;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    &--large {
        .input-number__value {
            @apply h-4xl w-5xl;
        }

        button {
            @apply h-4xl w-4xl;
        }
    }
}
