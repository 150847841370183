.reviews {
    &__button {
        margin: 30px 5px;
    }

    &__listing {
        margin: 0 auto;
        height: fit-content;
    }

    &__single,
    &__description {
        margin-top: 15px;
    }

    &__categories {
        color: white;
        border-radius: 10px 10px 0 0;

        &--background {
            background-color: $primary;
        }
    }

    &__categories:hover {
        background-color: $primary !important;
    }

    &__block {
        margin-top: 20px;
        padding: 30px;
        background-color: aliceblue;
        border-radius: 30px;
    }

    &__row {
        max-width: 300px !important;

        &--ellipsis {
            width: 300px !important;
            display: inline-block !important;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }

    &__cell {
        &--align-left {
            align-items: start;
        }
        &--align-center {
            align-items: center;
        }
    }
}
