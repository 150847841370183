.circuit-form {
    width: 100%;

    &__input-container {
        margin-bottom: 20px;
    }

    &__input {
        width: 400px;
    }
}

input[type='text']::placeholder {
    font-style: italic;
}

.tooltip {
    font-size: 12px;
}

.tippy-box[data-theme~='dkt'] {
    background: $tertiary;
}

.tippy-box[data-theme~='dkt'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: $tertiary;
}

.circuit-type-radio {
    margin: 16px 0;

    > label {
        font-weight: 600;
        color: $primary;
    }

    &__item {
        margin: 4px 0;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        gap: 15px;
        align-items: start;

        input {
            margin-top: 5px;
        }

        label:after {
            content: '' !important;
        }
    }
}

.input-top-helper {
    font-size: 14px;
    color: $primary;
}

.input-side-helper--right {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
}

.label {
    &--bold {
        font-weight: 600;
    }

    &--primary {
        color: $primary;
    }
}

.radio {
    display: flex;
    flex-direction: column;
}

input[type='radio'] + label {
    color: $primary !important;
    font-size: 14px !important;
}

.input--small {
    width: 150px;
}

// Removes unnecessary *
input[type='radio'] + label::after {
    content: '';
}

.disabled input,
.disabled label {
    cursor: not-allowed !important;
}
