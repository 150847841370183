.active-filter {
    background: $tertiary;
    color: #ffffff;
    border-color: $tertiary;
}

.tag {
    &.status-published {
        background-color: #ceffc9;
        color: #07592b;
    }

    &.status-draft {
        background-color: #dcdcdc;
        color: #4b4b4b;
    }

    &.status-archived {
        background-color: #d7c2ff;
        color: #2c007e;
    }

    &.status-not_published {
        background-color: #dfc9ff;
        color: #4c1700;
    }

    &.status-proofreading {
        background-color: #fff3a7;
        color: #4c2600;
    }

    &.status-closed {
        background-color: #ffcdcd;
        color: #4c0000;
    }

    &.status-unpublished {
        background-color: #ffc78c;
        color: #4c1700;
    }
}
