.circuit-tab-content {
    width: 100%;
    margin: 0 50px;
    max-width: 100%;

    &__container {
    }

    &__input-container {
        margin-bottom: 20px;
    }

    &__input {
        width: 400px;
    }
}
