.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    width: 100%;
    margin: auto;

    .common__input-container {
        margin-top: 0 !important;
    }
}

.reset-password__modal {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgb(0, 16, 24, 0.8);
    z-index: 10;

    &__inner {
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}
