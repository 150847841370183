// trick to avoid base css of tailwind to override vtmn-play/css classes
@layer tailwind {
    @tailwind base;
}
@tailwind components;
@tailwind utilities;

@import 'tippy.js/dist/tippy.css'; // optional for styling

@import '_variables.scss';
@import 'common.scss';
@import 'modal.scss';
@import 'user/login.scss';
@import 'admin/versions/listing.scss';
@import 'admin/confirmation-modal.scss';
@import 'admin/payment.scss';
@import 'admin/booking.scss';
@import 'circuit/circuits.scss';
@import 'circuit/circuit-pricing.scss';
@import 'circuit/circuit-informations.scss';
@import 'circuit/circuit-settings.scss';
@import 'circuit/circuit-program.scss';
@import 'circuit/circuit-accommodation.scss';
@import 'circuit/circuit-budget.scss';
@import 'circuit/form.scss';
@import 'circuit/circuit-slideshow.scss';
@import 'circuit/diff.scss';
@import 'circuit/select-multiple.scss';
@import 'layout/circuitTab.scss';
@import 'layout/layout.scss';
@import 'layout/sidebar.scss';
@import 'layout/nav.scss';
@import 'layout/focus.scss';
@import 'layout/fullpage.scss';
@import 'layout/card.scss';
@import 'partner/partners.scss';
@import 'review/reviews.scss';
@import 'user/users.scss';

textarea {
    height: 150px;
}

table {
    table-layout: fixed;
}

th,
td {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1.25rem;
    line-height: 1.5;
}
