.modal {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    opacity: 0;
    transition: 0.4s;

    &--show {
        display: flex;
        visibility: visible;
        opacity: 1;
    }
}

.modal--show.modal--inline {
    position: relative;
}
