@import './_variables.scss';

.common {
    &__title {
        margin-top: 10px;
        color: $primary;
        font-size: 25px;
        font-weight: 600;
    }

    &__title-divider:before,
    &__title-divider:after {
        height: 3px !important;
        background-color: $primary !important;
        margin-bottom: 10px;
    }

    &__subtitle {
        position: relative;
        text-transform: uppercase;
        color: $primary;
        font-size: 22px;
        font-weight: 600;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            height: 3px;
            width: 35%;
            border-bottom: 3px solid $tertiary;
        }
    }

    &__divider {
        margin: 30px 0;
        font-weight: 600;
        font-size: 30px;
        color: $primary;
    }

    &__divider-block {
        margin-top: 30px;
    }

    &__input,
    &__button {
        margin: auto 0;

        &--right {
            margin-left: auto;
        }

        &--size {
            max-width: 200px;
        }
    }

    &__button__container--fixed {
        position: fixed !important;
        bottom: 0 !important;
        left: 0;
        right: 0;
        width: 100%;
        padding: 10px 20px;
    }

    &__button--sticky {
        margin: 0 !important;
    }

    &__button--accent {
        background-color: $dkt-yellow;
        padding: 20px 40px;
        border-radius: 4px;
        font-weight: 600;
        color: $primary;
        font-size: 16px;
    }

    &__input-container {
        margin-top: 30px;

        &--flex {
            display: flex;
            flex-wrap: wrap;

            div {
                width: 200px;
                margin-left: 20px;
            }
        }
    }

    &__checkbox-container {
        margin-bottom: 30px;

        &--flex {
            display: flex;
            flex-wrap: wrap;

            div {
                margin: 10px;
            }
        }
    }

    &__input-radio-container {
        display: flex;

        & > label {
            color: $primary !important;
        }

        &--col {
            flex-direction: column;
        }

        :first-child {
            margin-right: 20px !important;
        }
    }

    &__input-radio-container > * {
        max-width: -webkit-fill-available !important;
    }

    &__input-radio {
        margin: 0 !important;
    }

    &__main {
        width: 100%;
        margin: 20px;
    }

    &__uppercase {
        text-transform: uppercase;
    }

    &__checkbox:before {
        background-color: white;
        border-radius: 5px !important;
    }

    &__information {
        color: $primary;
        margin: auto 0;
        font-size: smaller;
        vertical-align: middle;
        font-style: italic;

        &--margin-bottom {
            margin-bottom: 30px !important;
        }
    }
}

.required:after {
    content: ' *';
}

.hidden {
    display: none;
}

#drag-drop-area {
    margin: 50px 0;
}

[draggable] {
    user-select: none;
    -webkit-user-drag: element;
    cursor: move;
}

.logout__container {
    margin-right: 50px !important;
}

.logout__container a:active,
.logout__container a:hover {
    background-color: red !important;
    color: white;
    transition: 0.5s all ease;
}

.select {
    color: $primary;
    font-weight: 600;
    font-size: 16px;

    &--error {
        outline: 2px solid $red-expired;
    }
}

.input--invalid {
    box-shadow: inset 0 0 0 0.125rem $content-negative !important;
}

.input--valid {
    box-shadow: inset 0 0 0 0.125rem $content-positive !important;
}

.import-file {
    margin-top: 23px;

    .common__input-container {
        margin-top: 0;
        label {
            display: none;
        }
    }

    &__title {
        color: $primary;
        font-weight: 600;
        font-size: 16px;
    }

    // Hide input to allow styling
    input {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
    }

    // Click on label opens up file upload by default
    label {
        position: relative;
        width: fit-content;
        margin-top: 4px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 20px;
        color: $brand-dark;
        background: $brand-light;
        padding: 12.5px 20.5px;
        text-align: center;
        cursor: pointer;
    }

    &__filename {
        position: absolute;
        bottom: -35px;
        left: 0;
        font-size: 14px;
        font-weight: normal;
        color: $brand-dark;
        white-space: nowrap;
    }
}

.info {
    background: $info;
    border: 1px solid #007dbc;
    border-radius: 8px;
    color: $brand-dark;
}

.success {
    background: $success;
}

.warning {
    background: $warning;
}

.error {
    background: $error;
}

.filter-select {
    border: none;

    &--small {
        width: 70px !important;
    }

    &--large {
        width: 120px !important;
    }
}

.filter-input,
.filter-input input {
    width: 371px;
}

.help-text {
    font-size: 14px;
}

.table-cell {
    &--large {
        width: 400px;
        overflow-x: hidden;
    }

    &--small {
        width: 100px;
    }
}

.infobox {
    .svg,
    svg:not(.infobox__link svg) {
        width: 24px;
        height: 24px;
    }
}

.breakout {
    margin-left: -100%;
    margin-right: -100%;
}

// WYSIWYG editor
.ql-snow {
    background: white;
}

.ql-container {
    min-height: 150px;
}

.input-switch {
    @apply relative inline-block h-[30px] w-[60px] min-w-[60px];

    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 30px;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 30px;
    }

    input:checked + .slider {
        background-color: $blue-secondary;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $blue-secondary;
    }

    input:checked + .slider:before {
        transform: translateX(26px);
    }
}
