.card {
    max-width: 100%;
    min-width: -moz-min-content;
    min-width: min-content;
    border-radius: 4px;
    border: 1px solid $gray-secondary;
    box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background: #ffffff;
    padding: 16px;

    &__text {
        max-width: 550px;
    }

    img {
        aspect-ratio: 1/1;
    }

    &_variant {
        &--side-image {
            flex-direction: row;
            gap: 1rem;
            height: min-content;
            background: #ffffff;
            width: 100%;

            img {
                width: 300px !important;
                height: 150px;
            }

            > .card__content {
                margin-left: 0;
                width: 100%;
            }
        }
    }

    > .card__image {
        width: fit-content;
        height: 100%;
        position: relative;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        &--title {
            font-size: 1.25rem;
            font-weight: 600;
            min-width: max-content;
        }

        &__body {
            font-size: 0.875rem;

            &--small {
                font-size: 14px;
            }
        }
    }

    &__cta {
        display: flex;
        gap: 6px;
        font-weight: 600;
        align-items: center;
        color: $red-expired;

        svg {
            min-width: 16px;
            min-height: 16px;
        }
    }
}
