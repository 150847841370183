.fullpage-layout {
    width: 100%;
    height: 100vh;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        width: 400px;
    }

    &--publish {
        max-width: 400px;
    }

    &--errors {
        height: fit-content;
        min-height: 100vh;
        padding: 100px 0;

        > * {
            width: 700px;
        }
    }
}
