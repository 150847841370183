@import '../_variables.scss';

.payment {
    &-waiting {
        background-color: #ffc78c;
    }

    &-success {
        background-color: #ceffc9;
    }

    &-refund {
        background-color: #dcdcdc;
    }

    &-failed {
        background-color: #ffcdcd;
    }

    &-authorize {
        background-color: #fff3a7;
    }
}
