.sidebar {
    border-right: 1px solid $primary;
    min-height: 100vh;
    top: 0;
    max-height: calc(100% - 0px);
    transform: translateX(0%);
    width: 350px;
    background-color: white;

    &__content {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__image {
        padding: 4px !important;
        display: flex;
        height: 60px;
        border-bottom: 1px solid !important;
        background-color: $primary;
    }

    &__divider {
        margin: 20px 0;
    }

    &__user {
        text-align: center;
    }
}
