[data-backpack--mention-target='users'] {
    position: absolute;
    max-height: 200px;
    min-width: 200px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    overflow-y: auto;
    z-index: 1000;
    margin-top: 32px;
}

.mention-item {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.2s ease;
}

.mention-item:hover {
    background-color: #f7fafc;
}

.mention-item.active {
    background-color: #ebf4ff;
}

[data-backpack--mention-target='users']::-webkit-scrollbar {
    width: 6px;
}

[data-backpack--mention-target='users']::-webkit-scrollbar-track {
    background: #f7fafc;
    border-radius: 6px;
}

[data-backpack--mention-target='users']::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 6px;
}

[data-backpack--mention-target='users']:not(.hidden) {
    animation: slideIn 0.2s ease-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.mention-item span {
    color: #2d3748;
    font-size: 14px;
    font-weight: 500;
}

[data-backpack--mention-target='users']:empty {
    padding: 8px 12px;
    color: #718096;
    font-size: 14px;
    margin-top: 32px;
}

[data-backpack--mention-target='users']:empty::after {
    content: 'Aucun utilisateur trouvé';
}

[data-backpack--mention-target='users'].hidden {
    display: none;
}

span.mentioned-user {
    @apply text-content-brand;
}
