.circuit-settings {
    .input-side-helper--right {
        margin-top: 16px;
    }

    input[type='number'] {
        width: 150px !important;
    }

    input[type='radio'] + label {
        color: $primary !important;
        font-size: 14px !important;
    }
}
