.diff__item,
.diff__label,
.diff__item--label {
    text-align: left;
}

.diff__item {
    white-space: normal !important;
    text-align: left;
}

.diff__label {
    max-width: 300px;
    white-space: pre-line !important;
}

.diff__img {
    max-width: 200px !important;
    max-height: 200px;
}

.diff__version__property_collection span {
    margin-right: 4px;
}

.diff__added {
    background-color: lightgreen;
    border-radius: 4px;
    padding: 0 2px 0 2px;
}

.diff__removed {
    background-color: lightcoral;
    text-decoration: line-through;
    border-radius: 4px;
    padding: 0 2px 0 2px;
}
